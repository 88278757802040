import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { CartContext, ModalContext } from "../../context";
import _ from "lodash";
import { LocationsContext } from "../../context";
import { useMixpanel } from "gatsby-plugin-mixpanel";
import { useUpdateLocationBasedOnPostalCode } from "../../hooks";
import { useTrans } from "../../hooks";

const OrderType = ({ label, hasIcon, showModal }) => {
  const transMsg = useTrans();
  const locationsContext = useContext(LocationsContext);
  const cartContext = useContext(CartContext);
  const { setShowDeliveryModal } = useContext(ModalContext);
  const mixpanel = useMixpanel();
  const updateSelectedLocation = useUpdateLocationBasedOnPostalCode();

  // =========================================================
  return (
    <>
      <div className="row">
        <div className="col-12 ">
          {hasIcon && (
            <>
              <label
                className="my-1 mr-sm-2 mb-2 h6-text"
                htmlFor="inlineFormInputSelectTime"
              >
                <i
                  className="icofont-map-pins   icofont-1x mr-1"
                  style={{ color: "rgb(41, 41, 41)" }}
                ></i>
                {!_.isEmpty(label) && <>{label}:</>}
              </label>
              <br />
            </>
          )}

          <Button
            onClick={() => {
              if (
                !_.isEmpty(
                  locationsContext.selectedLocation?.externalDeliverylink
                )
              ) {
                locationsContext.setIsOpenExternalDeliveryModal(true);
                return;
              }
              updateSelectedLocation();
              cartContext.setOrderType("delivery");
              if (process.env.MIXPANEL_API_TOKEN)
                mixpanel.track("deliverySelected");
              if (showModal) setShowDeliveryModal(true);
            }}
            disabled={!locationsContext.selectedLocation?.delivery?.enable}
            className={
              cartContext.orderType === "delivery"
                ? "delivery-btn ml-2OFF active-btn"
                : "delivery-btn ml-2OFF"
            }
          >
            {transMsg("delivery")}
          </Button>
          <Button
            onClick={() => {
              cartContext.setOrderType("pickup");

              if (locationsContext.locations.length > 1)
                locationsContext.setIsOpenMultiLocationModal(true);

              if (process.env.MIXPANEL_API_TOKEN)
                mixpanel.track("pickupSelected");
            }}
            disabled={!locationsContext.selectedLocation?.pickup?.enable}
            className={
              cartContext.orderType === "pickup"
                ? "delivery-btn2 active-btn"
                : "delivery-btn2"
            }
          >
            {transMsg("pickup")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default OrderType;
