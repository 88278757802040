import React, { useContext, useEffect } from "react";
import { Form } from "react-bootstrap";

import AddressInput from "../../../components/AddressInput";
import NewAutoAddressInput from "../../../components/AddressInput/newAutocomplete";

import { CartContext, LocationsContext } from "../../../context";
import _ from "lodash";
import CheckoutAddressPickup from "./pickup";
import { useTrans } from "../../../hooks";
import produce from "immer";

const CheckoutAddressDelivery = () => {
  const cartContext = useContext(CartContext);
  const locationsContext = useContext(LocationsContext);
  const transMsg = useTrans();

  useEffect(() => {
    if (_.isEmpty(locationsContext)) return;
    if (_.isEmpty(locationsContext.selectedLocation)) return;

    const populateAddress = {
      countryCode: _.upperCase(
        locationsContext.selectedLocation?.address?.countryCode || "CA"
      ),
      country: locationsContext.selectedLocation.address.country,
      city: locationsContext.selectedLocation.address.city,
      province: locationsContext.selectedLocation.address.province,
    };
    if (_.isEmpty(cartContext.shippingAddress))
      cartContext.setShippingAddress({
        ...cartContext.shippingAddress,
        ...populateAddress,
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationsContext]);

  useEffect(() => {
    if (_.isEmpty(cartContext.shippingAddress)) return;

    if (cartContext.bothAddressesAreEqual)
      cartContext.setBillingAddress(cartContext.shippingAddress);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartContext.shippingAddress, cartContext.bothAddressesAreEqual]);

  return (
    <>
      <div className="">
        <h2> {transMsg("deliveryAddress")}</h2>
        <div className="pt-3">
          {process.env.GATSBY_GOOGLE_MAPS_API_KEY ? (
            <>
              {!_.isEmpty(cartContext?.shippingAddress?.lat) &&
                !_.isEmpty(cartContext?.shippingAddress?.long) && (
                  <AddressInput
                    uniqueKey="DeliveryAddress"
                    address={cartContext.shippingAddress}
                    setAddress={cartContext.setShippingAddress}
                    fieldAttributes={{
                      phone: {
                        display: false,
                        required: false,
                        defaultValue: cartContext.cartPhone,
                      },
                      address_1: { display: false, require: false },
                      address_2: { display: false, require: false },
                      city: { display: false, require: false },
                      province: { display: false, require: false },
                      country: { display: false, require: false },
                      postalCode: { display: false, require: false },
                    }}
                  />
                )}

              <div className="mb-3">
                <NewAutoAddressInput
                  uniqueKey="DeliveryAddressModal"
                  address={cartContext.shippingAddress}
                  onDone={(address) => {
                    cartContext.setShippingAddress(
                      produce(cartContext.shippingAddress, (draft) => {
                        _.map(address, (v, k) => {
                          draft[k] = v;
                        });
                      })
                    );
                  }}
                />
              </div>
            </>
          ) : (
            <>
              <AddressInput
                uniqueKey="DeliveryAddress"
                address={cartContext.shippingAddress}
                setAddress={cartContext.setShippingAddress}
                fieldAttributes={{
                  phone: {
                    required: false,
                    defaultValue: cartContext.cartPhone,
                  },
                }}
              />
            </>
          )}
        </div>

        <Form.Check
          type="checkbox"
          label={transMsg("billingSameAsShipping")}
          checked={cartContext.bothAddressesAreEqual}
          onChange={() => {
            cartContext.setBothAddressesAreEqual(
              !cartContext.bothAddressesAreEqual
            );
          }}
        />
      </div>

      {!cartContext.bothAddressesAreEqual && (
        <>
          <hr />
          <div className="mt-4">
            <CheckoutAddressPickup />
          </div>
        </>
      )}
    </>
  );
};

export default CheckoutAddressDelivery;
