import React, { useContext, useEffect, useState } from "react";
import { CartContext, LocationsContext } from "../../context";
import PhoneInput from "react-phone-number-input";
import _ from "lodash";
import { useTrans } from "../../hooks";

const UpdateCheckoutPhone = () => {
  const transMsg = useTrans();
  const { cartPhone, setCartPhone } = useContext(CartContext);
  const [defaultCountry, setDefaultCountry] = useState("");
  const { selectedLocation } = useContext(LocationsContext);
  useEffect(() => {
    if (_.isEmpty(selectedLocation?.address?.countryCode)) return;
    setDefaultCountry(selectedLocation.address.countryCode);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PhoneInput
        placeholder={transMsg("phone")}
        value={cartPhone}
        onChange={setCartPhone}
        defaultCountry={defaultCountry || ""}
      />
    </>
  );
};

export default UpdateCheckoutPhone;
