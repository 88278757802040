import React, { useContext } from "react";

import Layout from "../../components/Layout";
import {
  CartContext,
  MaintenanceContext,
  LocationsContext,
} from "../../context";
import { Button, Container, Form, Spinner } from "react-bootstrap";
import UpdateCheckoutEmail from "./updateEmail";
import UpdateCheckoutPhone from "./updatePhone";
import CheckoutAddress from "./address";
import useSyncCart from "./useSyncCart";
import ScheduleTime from "../../components/ScheduleTime";
import OrderType from "../../components/OrderType";
import _ from "lodash";
import CannotDeliverMessage from "../../components/CannotDeliverMessage";
import { useAlert } from "react-alert";
import { useTrans, useNavigate } from "../../hooks";

const Checkout = ({ pageContext }) => {
  const transMsg = useTrans();
  const navigate = useNavigate();
  const { seoData, layoutProps } = pageContext;
  const { cartInSync } = useSyncCart();
  const { isUnderMaintenance } = useContext(MaintenanceContext);
  const alert = useAlert();
  const cartContext = useContext(CartContext);

  const validation = () => {
    if (
      _.isUndefined(cartContext.cartPhone) ||
      _.isEmpty(cartContext.cartPhone)
    )
      return "Please enter you phone";

    if (_.isEmpty(cartContext.scheduleTime)) return "Please select a time";
    if (_.isEmpty(cartContext.orderType)) return "Please select a method";

    return "";
  };
  return (
    <Layout seo={seoData} fixedHeader showLocationInfo {...layoutProps}>
      <Container>
        <div className="p-5">
          <div className="row mt-5 ">
            {isUnderMaintenance ? (
              <>
                <MaintenanceBody />
              </>
            ) : (
              <>
                <div className="offset-lg-2 col-lg-8 ">
                  <div
                    style={{
                      opacity: cartInSync ? 0.35 : 1,
                      pointerEvents: cartInSync ? "none" : "auto",
                    }}
                  >
                    {cartInSync && (
                      <div
                        style={{
                          position: "fixed",
                          top: 0,
                          bottom: 0,
                          right: 0,
                          left: 0,
                        }}
                      >
                        <Spinner
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                          }}
                          animation="border"
                          variant="info"
                        />
                      </div>
                    )}
                    <CannotDeliverMessage showAlert />
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        const valid = validation();
                        if (!_.isEmpty(valid)) alert.error(valid);
                        else navigate("/checkout/payment");
                      }}
                    >
                      <CheckoutDetails />
                      <Button
                        className="btn btn-warning btn-block mt-5 py-3 text-dark text-center dropdown-item btn-checkout bold"
                        type="submit"
                      >
                        {transMsg("next")}
                      </Button>
                    </Form>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Container>
    </Layout>
  );
};

const CheckoutDetails = () => {
  const cartContext = useContext(CartContext);
  const transMsg = useTrans();

  return (
    <>
      {/* EMAIL */}
      <div className="row">
        <div className="col-12 col-md-6 pt-4 ">
          <UpdateCheckoutEmail />
        </div>
      </div>
      {/* phone */}
      <div className="row">
        <div className="col-12 col-md-6 pt-4 ">
          <UpdateCheckoutPhone />
        </div>
      </div>
      {/* Method */}
      <div className="row">
        <div className="col-12 col-md-6 pt-4 ">
          <OrderType label={transMsg("method")} hasIcon />
        </div>
      </div>
      {/* ScheduleTime */}
      <div className="row">
        <div className="col-12 col-md-6 pt-4 ">
          <ScheduleTime label={transMsg("scheduleTime")} hoursType="auto" />
        </div>
      </div>
      <hr className="mt-5" style={{ borderWidth: 2, borderColor: "black" }} />

      {/* ADDRESS */}
      <div className="row">
        <div className="col-12">
          <CheckoutAddress />
        </div>
      </div>
      <hr className="mt-5" style={{ borderWidth: 2, borderColor: "black" }} />
      {/* NOTE */}
      <div className="row py-2 mb-2">
        <div className="col-12">
          <label
            className="my-1 mr-sm-2 mb-2 h6-text"
            htmlFor="inlineFormInputSelectTime"
          >
            <i
              className="icofont-ui-note icofont-1x mr-1"
              style={{ color: "rgb(41, 41, 41)" }}
            ></i>
            {_.capitalize(transMsg(cartContext.orderType || "EMPTY"))}{" "}
            {transMsg("instructions")}:
          </label>
          <Form.Group
            controlId="exampleForm.ControlTextarea1"
            style={{
              marginBottom: "0px",
              marginTop: "15px",
            }}
          >
            <Form.Control
              as="textarea"
              placeholder={transMsg("addANoteOrInstructions") + "..."}
              rows={4}
              className="special-ins"
              value={cartContext.cartNote || ""}
              onChange={({ target: { value } }) => {
                cartContext.setCartNote(value);
              }}
            />
          </Form.Group>
        </div>
      </div>
    </>
  );
};

const MaintenanceBody = () => {
  const { selectedLocation } = useContext(LocationsContext);
  const transMsg = useTrans();

  return (
    <>
      <div className="col-12">{transMsg("underMaintenanceContent1")}</div>

      <div className="col-12">{transMsg("underMaintenanceContent2")}</div>

      <div className="col-12 mt-3">
        <a className=" ml-1 " href={`tel:${selectedLocation?.address?.phone}`}>
          {selectedLocation?.address?.phone}
        </a>
      </div>
    </>
  );
};
export default Checkout;
